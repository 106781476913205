<template>
  <div class="card shadow-sm bg-white border p-8">
    <div class="text-start">
      <h3 class="font-bold">Lista de {{ route.name }}</h3>
      <span class="text-sm text-gray-500">Aqui você pode observar todos os itens relacionados aos {{
        route.name.toLowerCase() }} como
        suas respectivas ações.
      </span>
    </div>


    <div class="flex flex-row items-center mt-3">
      <div class="w-full p-2">
        <Input type="select" id="tipo" v-model="tipoSelect" label="Tipo" placeholder="Selecione um Tipo"
          :data="tipoOption" @keyupSearch="geTipo" />

      </div>
      <div class="justify-center">
        <Button color="primary" text="Filtrar" @click="filter()" />
      </div>
    </div>
    <div class="flex mb-6 mt-5 justify-end">
      <router-link to="/setores/criar">
        <Button color="primary" :text="`Criar novo setor`"></Button>
      </router-link>
    </div>



    <div class="card">
      <DataTable :headers="headers" :data="data" :options="options" :exports="true" color="primary" />
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE, POST } from "../../services/api";
import { useRoute } from "vue-router";
import { isEmpty } from "../../services/validate";
import Input from '@/components/Elements/Input.vue';

export default {
  name: "Setores",
  components: {
    DataTable,
    Button,
    Input,
  },

  data() {
    return {

    };
  },

  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Nome", value: "nome" },
      { key: "Ordem", value: "ordenar" },
      { key: "Tipo", value: "tipo" },
      { key: "Ações", value: "" },
    ];

    const tipoOption = ref([
      {
        label: 'Todos',
        value: '4'
      },
      {
        label: 'Público',
        value: '1'
      },
      {
        label: 'Produtos',
        value: '2'
      },
      {
        label: 'Fichas',
        value: '3'
      }])

    const tipoSelect = ref('4')

    const route = useRoute();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);
    const setor = ref({
      nome: "",
      ordenar: "1",
    });


    onMounted(async () => {
      const maps = await GET("setores");

      const mappedArray = maps.map((x) => {
        if (x.tipo === 1) {
          x.tipo = 'Público'
        }
        if (x.tipo === 2) {
          x.tipo = 'Produtos'
        }
        if (x.tipo === 3) {
          x.tipo = 'Fichas'
        }
        return x
      }
      );
      data.value = mappedArray

    });

    const deleteSetor = (item) => {
      loader.open();
      const deleteFunction = async function () {
        try {
          loader.open();
          const indexItem = data.value.indexOf(item)
          await DELETE(`setores/${item.id}`);
          data.value.splice(indexItem, 1)

          loader.close();
          alert.open("Sucesso!", `Setor deletado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      };
      modal.open(
        "Atenção",
        "Deseja realmente excluir esse setor?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    const create = async () => {
      const validateNome = isEmpty(setor.value.nome);
      const validateOrdenar = isEmpty(setor.value.ordenar);

      if (validateNome || validateOrdenar) {
        alert.open("Atenção!", validateNome, "warning");
        alert.open("Atenção!", validateOrdenar, "warning");
      } else {
        loader.open();
        try {
          await POST("setores", setor.value);
          alert.open(
            "Sucesso!",
            `Setor <b>${setor.value.nome}</b> criado com sucesso!`,
            "success"
          );

          data.value.push(setor.value);
          loader.close();
        } catch (e) {
          alert.open("Atenção!", e, "danger");
          loader.close();
        }
      }
    };

    const filter = async () => {
      try {
        loader.open()
        let maps;
        if (tipoSelect.value !== '4') {
          maps = await GET(`setores/filtro/${tipoSelect.value}`)
        } else {
          maps = await GET(`setores`)
        }

        const mappedArray = maps.map((x) => {
          if (x.tipo === 1) {
            x.tipo = 'Público'
          }
          if (x.tipo === 2) {
            x.tipo = 'Produtos'
          }
          if (x.tipo === 3) {
            x.tipo = 'Fichas'
          }
          return x
        });

        data.value = mappedArray

        loader.close()
      } catch (error) {
        loader.close()
        alert.open("Erro", error, "danger");
      }
    }

    const options = [
      {
        icon: "pen",
        path: "/setores/editar",
        title: "Editar Setores",
      },
      { icon: "trash", action: (param) => deleteSetor(param), title: "Excluir Setor" },
    ];

    return { headers, data, options, route, create, setor, tipoSelect, tipoOption, filter };
  },
};


</script>

<style>
.btn {
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
